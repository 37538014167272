import {PackageFacets, PlanFacets, ProductFacets} from '../generated/graphql';
import {SortOrder, SortByField} from '../utils/sorting';

export const EMPTY_DEFAULT_IDENTIFIER = 'EMPTY_DEFAULT';

export enum ControlType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Text = 'text',
  Slider = 'slider'
}

export enum FilterType {
  Checkbox = 'checkbox',
  Badge = 'badge',
  Range = 'range',
  Text = 'text'
}

export enum SearchType {
  In = 'In',
  Range = 'Range',
  RangeMin = 'RangeMin',
  RangeMax = 'RangeMax',
}
export interface AdvisorConfig {
  category: string;
  filters: BaseFilter[];
  featuredSpecs?: ProductSpec[];
  subSpecs?: ProductSpec[];
}

export interface ProductSpec {
  key: string;
  formatter?: (value: string) => string;
  pluralSuffix: string;
  singularSuffix: string;
  showFirst: boolean;
}


export interface FilterOption {
  label: string;
  value: number | string | boolean;
  secondaryValue?: number | string;
  url?: string;
}

export interface OptionProps {
  option: FilterOption;
  compact: boolean;
}

interface CreateFilterInput {
  identifier: string;
  label: string;
  controlType: ControlType;
  Description?: React.FunctionComponent | string;
  filterType?: FilterType;
  searchType?: SearchType;
  append?: string;
  Option?: React.FunctionComponent<OptionProps>;
  gridCols: number;
  minOptions?: number;
  onlyShowFilterIfHasTerms?: boolean;
  withoutOptions?: boolean;
  options?: FilterOption[];
  featured?: boolean;
  initialSortBy?: SortByField;
  featuredSortBy?: SortByField;
  nonFeaturedSortBy?: SortByField;
}

export interface BaseFilter {
  identifier: string;
  label: string;
  controlType: ControlType;
  Description?: React.FunctionComponent | string;
  searchType: SearchType;
  filterType: FilterType;
  append: string;
  Option?: React.FunctionComponent<OptionProps>;
  options?: FilterOption[];
  nullableOptions?: boolean;
  gridCols: number;
  minOptions: number;
  onlyShowFilterIfHasTerms: boolean;
  withoutOptions: boolean;
  featured?: boolean;
  initialSortBy?: SortByField;
  featuredSortBy?: SortByField;
  nonFeaturedSortBy?: SortByField;
}

export interface BaseFilterProps {
  filter: BaseFilter;
  handleOnChange: (filter: BaseFilter, option: FilterOption) => void;
  selectedOptions: any;
  compact?: boolean;
}

export const createFilter = ({
  identifier,
  label,
  controlType,
  Description,
  searchType = SearchType.In,
  filterType = FilterType.Checkbox,
  append = '',
  Option,
  gridCols,
  minOptions = 2,
  options = undefined,
  onlyShowFilterIfHasTerms = false,
  withoutOptions = false,
  featured,
  initialSortBy = {field: 'count', order: SortOrder.ASC},
  featuredSortBy = {field: 'value', order: SortOrder.ASC},
  nonFeaturedSortBy = {field: 'value', order: SortOrder.ASC},
}: CreateFilterInput): BaseFilter => ({
  identifier,
  label,
  controlType: featured ? ControlType.Radio : controlType,
  Description,
  searchType,
  filterType,
  Option,
  append,
  gridCols,
  minOptions,
  options,
  onlyShowFilterIfHasTerms,
  withoutOptions,
  nullableOptions: Boolean(featured),
  featured,
  initialSortBy,
  featuredSortBy,
  nonFeaturedSortBy,
});

type Facets = ProductFacets | PlanFacets | PackageFacets;

export const facetsToFilters = (config: AdvisorConfig, facets: Facets) => {
  return config.filters
    .map((filter) => {
      if (filter.withoutOptions || (filter.options && !filter.onlyShowFilterIfHasTerms)) {
        return filter;
      }

      const options = (facets as any[])[`${filter.identifier}Terms` as any] || [];
      if (filter.onlyShowFilterIfHasTerms) {
        return options.length > 1 ? filter : {...filter, options: []};
      }
      return {
        ...filter,
        options: options
          .map((option: any) => ({
            ...option,
            label: createFilterOptionLabel(option.value, filter.append),
          })),
      };
    }).filter((filter) => filter.withoutOptions || filter.options?.length > 0)
    .reduce((prev, cur) => ({...prev, [cur.identifier]: cur}), {});
};

export const filterGridColsClass = (gridCols: number) => ({
  /* eslint-disable @typescript-eslint/naming-convention */
  1: 'sf-grid-cols-1',
  2: 'sf-grid-cols-2',
  3: 'sf-grid-cols-3',
  /* eslint-enable @typescript-eslint/naming-convention */
}[gridCols]);

const createFilterOptionLabel = (value: string | number, append: string) => `${value}${append || ''}`;
