export const enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortByField {
  field: string;
  order: SortOrder;
}

export default function sortBy(array: any[], key: string, sortOrder: SortOrder = SortOrder.ASC) {

  const sortedAsc = array.sort(
    (primary: any, secondary: any) => {
      const primaryValue = primary[key];
      const secondaryValue = secondary[key];
      if (primaryValue < secondaryValue) {
        return -1;
      }
      if (primaryValue > secondaryValue) {
        return 1;
      }
      return 0;
    },
  );

  switch (sortOrder) {
    case SortOrder.DESC:
      return sortedAsc.reverse();
    default:
      return sortedAsc;
  }
}
